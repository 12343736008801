import consumer from "./consumer"

$(document).ready(function() {
  if ($('#service_queue_waiting_channel').length > 0) {
    let service_queue_id = $('#service_queue_waiting_channel').attr('data-service-queue-id');

    consumer.subscriptions.create({
      channel: `ServiceQueueCable::WaitingChannel`,
      service_queue_id: service_queue_id
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('Conectado! - service_queue_waiting_channel');
      },

      disconnected() {
        console.log('Desconectado! - service_queue_waiting_channel');
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        console.log('Informação recebida - service_queue_waiting_channel')
        console.log(data);

        let table = $('#waiting_table');
        if (data.action === 'receive') {
          table.children('tr').remove();
          $('#current-link').css('color', '');
          $('#current-link').css('font-weight', '');

          // console.log(data.consumers);
          data.consumers.forEach(function (el) {
            table.append(`<tr id="consumer_service_queue_${el.id}" style="display: none">
              <td width="20%">${el.show_name}</td>
              <td width="15%">${el.priority_type_code}</td>
              <td width="20%"></td>
              <td>${el.created_at}</td>
             </tr>`);
            setInterval(function() { table.children('tr').fadeIn('slow') }, 200 );
          })
        }

        if (data.action === 'update') {
          if (Math.ceil(data.counter)) {
            $('#call-title').html(`${data.counter}ª Chamada`)
          } else {
            $('#call-title').html(`Chamadas realizadas`)
          }
        }

        if (data.action === 'remove') {
          $('#current-link').css('color', 'red');
          $('#current-link').css('font-weight', 'bold');

          table.children(`tr#consumer_service_queue_${data.consumer.id}`).remove();
        }
      },
    })
  }
});
