import consumer from "./consumer"

$(document).ready(function() {
  if ($('#preview_painel_already_called_channel').length > 0) {
    let preview_pane_id = $('#preview_painel_already_called_channel').attr('data-preview-pane-id');

    consumer.subscriptions.create({
      channel: `PreviewPainelCable::AlreadyCalledChannel`,
      preview_pane_id: preview_pane_id
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('Conectado! - preview_painel_already_called_channel');
      },

      disconnected() {
        console.log('Desconectado! - preview_painel_already_called_channel');
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        console.log('Informação recebida - preview_painel_already_called_channel')
        console.log(data);

        if (data.action === 'receive') {
          $('.alredy_called').prepend(`
            <div class="mb-5" id="alredy_called_${data.consumer.id}">
              <h1 class="called-name truncate bracelet_${data.consumer.bracelet_color}">${data.consumer.show_name}</h1>
              <div class="card called-card">
                <h3>${data.consumer.location_description}</h3>
              </div>
            </div>
          `);

          while ($('.alredy_called .mb-5').length > 4) {
            $('.alredy_called div').last().remove();
          }
          stopRepeatPlaylist();

          return;
        }
      },
    })
  }
});


