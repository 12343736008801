import consumer from "./consumer"

$(document).ready(function() {
  if ($('#service_queue_already_called_channel').length > 0) {
    let service_queue_id = $('#service_queue_already_called_channel').attr('data-service-queue-id');

    consumer.subscriptions.create({
      channel: `ServiceQueueCable::AlreadyCalledChannel`,
      service_queue_id: service_queue_id
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('Conectado! - service_queue_already_called_channel');
      },

      disconnected() {
        console.log('Desconectado! - service_queue_already_called_channel');
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        console.log('Informação recebida - service_queue_already_called_channel')
        console.log(data);

        if (data.action === 'receive') {
          $(`#consumer_service_queue_${data.consumer.id}`).remove();
        }
      },
    })
  }
});
