import consumer from "./consumer"

$(document).ready(function() {
  if ($('#preview_painel_waiting_to_presence_channel').length > 0) {
    let preview_pane_id = $('#preview_painel_waiting_to_presence_channel').attr('data-preview-pane-id');

    consumer.subscriptions.create({
      channel: `PreviewPainelCable::WaitingToPresenceChannel`,
      preview_pane_id: preview_pane_id
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('Conectado! - preview_painel_waiting_to_presence_channel');
      },

      disconnected() {
        console.log('Desconectado! - preview_painel_waiting_to_presence_channel');
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        console.log('Informação recebida - preview_painel_waiting_to_presence_channel')
        console.log(data);

        if (data.action === 'receive') {
          $('.patient-name').html(`
            <div class='consumer_${data.consumer.id} bracelet_${data.consumer.bracelet_color}'>${data.consumer.show_name}</div>`
          );
          $('.place-name').html(`
            <div class='consumer_${data.consumer.id}'>${data.consumer.go_to}</div>`
          );
          repeatPlaylistPlay(data.playlist, 4, this);

          return;
        }
        if (data.action === 'remove') {
          $(`.patient-name .consumer_${data.consumer.id}`).remove();
          $(`.place-name .consumer_${data.consumer.id}`).remove();
          stopRepeatPlaylist();

          return;
        }
      },
    })
  }
});
